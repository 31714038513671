import React, { useEffect, useRef, useState, useMemo } from 'react';
import { GetServerSideProps } from 'next';
import YotpoProductReviewsProvider from '@/context/YotpoProductsContext';
import FlexIntro from '@/components/organisms/flexIntro';
import ChoosePlanSection from '@/components/organisms/choosePlanSection';
import HowItWorksSection from '@/components/organisms/howItWorksSection';
import DetailsSection from '@/components/organisms/theDetail';
import WhyLoveFlex from '@/components/organisms/whyLoveFlex';
import Testimonials from '@/components/organisms/testimonials';
import FAQ from '@/components/organisms/faq';
import StickyHeader from '@/components/organisms/stickyHeader';
import { GET_HOMEPAGE_PHONES } from '@/graphql/Queries';
import apolloClient from '@/lib/ApolloClient';
import { ContentfulButtonLink, HomePageProduct } from 'globalTypes';
import FallbackScreen from '@/components/organisms/fallbackscreen';
import useLayoutData from '@/hooks/LayoutData';
import useFetchContent from '@/hooks/Content';
import { useUser } from '@/context/UserContext';
import { transformProducts } from '@/utilities';
import { NextSeo } from 'next-seo';
import logger from '@/lib/logger';
import { pageViewGtm } from '@/lib/Gtmlib';
import { getHomePageDataContent } from '@/lib/contentful';
import { getCustomerAccessToken } from '@/lib/Cookielib';
import { ChakraProvider } from '@chakra-ui/react';
import NotifyMeProvider, { NotifyMeContext } from '@/context/NotifyMeContext';

export type HomePageDataProps = {
  error?: string | null;
  customerAccessToken?: string | null;
  homepagedata: Record<string, any> | null;
  homepageproducts: Record<string, any>;
};

export default function Homepage(props: HomePageDataProps) {
  const {
    error: homepageErr,
    customerAccessToken,
    homepagedata,
    homepageproducts,
  } = props;

  let { isModalOpen } = React.useContext(NotifyMeContext);

  const { authenticationUserServerSide, loggedIn } = useUser();

  if (!loggedIn) authenticationUserServerSide(customerAccessToken);
  useLayoutData();
  useFetchContent();

  const [showStickyHeader, setShowShowStickyHeader] = useState<boolean>(false);
  const [heightToSection, setHeightToSection] = useState<number>(0);
  const howItWorksRef: React.MutableRefObject<HTMLDivElement | null> =
    useRef(null);
  const choosePlanRef: React.MutableRefObject<HTMLDivElement | null> =
    useRef(null);

  const extractProductData = useMemo<HomePageProduct[]>(
    () => transformProducts(homepageproducts),
    [homepageproducts]
  );

  const calculateHeight = () => {
    if (howItWorksRef.current) {
      const { top } = howItWorksRef.current.getBoundingClientRect();
      setHeightToSection(top + window.scrollY);
    }
  };

  setInterval(() => {
    calculateHeight();
  }, 2000);

  useEffect(() => {
    pageViewGtm();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;

      if (position > heightToSection) {
        setShowShowStickyHeader(true);
      } else {
        setShowShowStickyHeader(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [heightToSection]);

  function moveToChoosePlanSection() {
    choosePlanRef.current?.scrollIntoView({ behavior: 'smooth' });
  }

  const howItWorks = homepagedata?.howItWorks?.map(
    (i: Record<string, any>) => i.fields
  );

  const detailsSection = homepagedata?.detailsSection?.fields;
  const loveFlexSection = homepagedata?.loveGazelleFlex?.fields;
  const FAQsectionTopParagraph = homepagedata?.faqTopParagraph;
  const FAQsection = homepagedata?.frequentyAskedQuestions?.map(
    (question: Record<string, any>, index: number) => ({
      question: question.fields.mainText,
      answer: `${question.fields.childText}`,
    })
  );
  const loveFlexImage = homepagedata?.whyyoulovefleximage;

  const customerReviews = homepagedata?.whatCustomersAreSaying?.map(
    (i: Record<string, any>) => i.fields
  );

  const helpLink = homepagedata?.links?.find(
    (l: ContentfulButtonLink) => l.name === 'helpCenter'
  );

  useLayoutData();

  if (homepageErr) {
    return <FallbackScreen />;
  }

  return (
    <ChakraProvider>
      <NotifyMeProvider>
        <div>
          <NextSeo title="Rent Cell Phones - Gazelle Certified" />
          <FlexIntro
            mainHeading={homepagedata?.flexIntroMainHeading}
            h2title={homepagedata?.flexIntroH2Title}
            paymentStatement={homepagedata?.flexIntroPaymentStatement}
            refurbishedStatement={homepagedata?.flexIntroRefurbishedStatement}
            monthStatement={homepagedata?.flexIntroMonthStatement}
            imageUrl={homepagedata?.flexIntroImage.fields.file.url}
            handleSubscribe={moveToChoosePlanSection}
            subscribeButtonText={homepagedata?.subscribeButtonText}
            flexIntroSubscriberQuestion={
              homepagedata?.flexIntroSubscriberQuestion
            }
            mobileRegular={homepagedata?.mobileRegular}
            circularDollarRegular={homepagedata?.circularDollarRegular}
            calendarRegular={homepagedata?.calendarRegular}
            upgradeEligibility={homepagedata?.upgradeEligibility}
          />
          <section ref={choosePlanRef}>
            <YotpoProductReviewsProvider>
              {extractProductData && (
                <ChoosePlanSection
                  flexProducts={extractProductData}
                  homepagedata={homepagedata}
                />
              )}
            </YotpoProductReviewsProvider>
          </section>
          {showStickyHeader && extractProductData ? (
            <StickyHeader
              products={extractProductData}
              subscribeButtonText={homepagedata?.subscribeButtonText}
            />
          ) : null}
          <section ref={howItWorksRef}>
            {howItWorks && <HowItWorksSection howItWorks={howItWorks} />}
          </section>
          <section id={'details-section'}>
            {detailsSection && <DetailsSection details={detailsSection} />}
          </section>
          <section id={'why-love-flex'} className="pt-[2.5rem]">
            {loveFlexSection && (
              <WhyLoveFlex
                fields={loveFlexSection}
                loveFlexImage={loveFlexImage}
              />
            )}
          </section>
          <section id={'testimonials'}>
            {customerReviews && <Testimonials reviews={customerReviews} />}
          </section>
          <section id={'faq'}>
            {FAQsectionTopParagraph && (
              <FAQ
                faqs={FAQsection}
                topParagraph={FAQsectionTopParagraph}
                helpLink={helpLink}
              />
            )}
          </section>
        </div>
      </NotifyMeProvider>
    </ChakraProvider>
  );
}

export const getServerSideProps: GetServerSideProps = async ({ req, res }) => {
  const customerAccessToken = getCustomerAccessToken(req, res);

  try {
    const products = apolloClient.query({
      query: GET_HOMEPAGE_PHONES,
      variables: {
        handle: 'smartphones',
      },
    });

    const homepagedata = await getHomePageDataContent();
    const data = await Promise.all([products, homepagedata]);

    const homepageproducts = data[0]?.data?.collection?.products?.edges ?? null;

    if (!homepagedata && !homepageproducts) {
      throw new Error('Data not found');
    }

    return {
      props: {
        error: null,
        customerAccessToken: customerAccessToken ?? null,
        homepagedata,
        homepageproducts,
      },
    };
  } catch (error: any) {
    logger.error('error to show', error);
    return {
      props: {
        error: error.message,
        customerAccessToken: customerAccessToken ?? null,
        homepagedata: null,
        homepageproducts: null,
      },
    };
  }
};
