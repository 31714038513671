import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import FlexCarousel from '../../molecules/FlexCarousel';
import TestimonialCard from '../testimonialCard';
import { TestimonialsProps, TestimonialItem } from './types';

export default function Testimonials(props: TestimonialsProps) {
  const { reviews } = props;
  const [startIndex, setStartIndex] = useState(0);
  const [windowDimensions, setWindowDimensions] = useState(0);
  const [displayedReviews, setDisplayedReviews] = useState<TestimonialItem[]>(
    []
  );
  useEffect(() => {
    setDisplayedReviews(
      reviews?.slice(startIndex, startIndex + reviews.length)
    );
  }, [startIndex, reviews]);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  const handleResize = () => {
    setWindowDimensions(window.innerWidth);
  };

  const getSlidePercentage = () => {
    if (windowDimensions > 0) {
      if (windowDimensions <= 400) {
        return 70;
      }
      if (windowDimensions <= 600) {
        return 65;
      }
      if (windowDimensions <= 800) {
        return 40;
      }
      if (windowDimensions <= 1000) {
        return 35;
      }
    }
    return 30;
  };

  const getItemsToShow = () => {
    if (windowDimensions > 0) {
      if (windowDimensions >= 1280 && windowDimensions <= 1630) {
        return 3;
      }
    }

    return 4;
  };

  return (
    <section className={'w-full xl:pl-48 xl:py-16 bg-[#F2F2F2]'}>
      <div className={'hidden xl:flex'}>
        <FlexCarousel
          totalItems={displayedReviews.length}
          itemsToShow={getItemsToShow()}
          startIndex={startIndex}
          setStartIndex={setStartIndex}
          title={'What customers are saying'}
        >
          {displayedReviews.map((review, index) => (
            <div key={index} className="mr-5" data-testid={'testimonial-card'}>
              <TestimonialCard
                title={review.title}
                userImage={review.userImage?.fields?.file?.url}
                description={review.description}
                name={review.userName}
              />
            </div>
          ))}
        </FlexCarousel>
      </div>
      <div className={'xl:hidden w-full flex flex-col'}>
        <h2 className={'text-[32px] text-center font-bold mb-4 pt-8'}>
          What folks like you are saying about Flex{' '}
        </h2>
        <div className="w-full flex flex-row">
          <Carousel
            showArrows={false}
            showIndicators={true}
            showThumbs={false}
            infiniteLoop={false}
            dynamicHeight={false}
            selectedItem={0}
            preventMovementUntilSwipeScrollTolerance={true}
            swipeScrollTolerance={50}
            centerMode={true}
            centerSlidePercentage={getSlidePercentage()}
            showStatus={false}
            width="100vw"
            className="w-inherit"
          >
            {reviews.map((review, index) => (
              <div
                key={index}
                className={'ml-[8px] mr-[8px] flex justify-center items-center'}
                data-testid={'testimonial-card'}
              >
                <TestimonialCard
                  title={review.title}
                  userImage={review.userImage?.fields?.file?.url}
                  description={review.description}
                  name={review.userName}
                />
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </section>
  );
}
