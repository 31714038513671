import React, { useState, useEffect } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import ChoosePlanCard from '../choosePlanCard';
import Link from 'next/link';
import { HomePageProduct } from '#/globalTypes';
import { FlexProducts } from './types';

export default function ChoosePlanSection(props: FlexProducts) {
  const { flexProducts, homepagedata } = props;

  const flexProductSort: HomePageProduct[] = [];

  flexProducts.forEach((product) => {
    if (product.handle === 'iphone-12') {
      flexProductSort[0] = product;
    } else if (product.handle === 'iphone-13') {
      flexProductSort[1] = product;
    } else if (product.handle === 'iphone-14') {
      flexProductSort[2] = product;
    }
  });

  const [windowDimensions, setWindowDimensions] = useState(0);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleResize = () => {
    setWindowDimensions(window.innerWidth);
  };

  const getSlidePercentage = () => {
    switch (true) {
      case windowDimensions > 0 && windowDimensions <= 340:
        return 95;
      case windowDimensions > 340 && windowDimensions <= 360:
        return 90;
      case windowDimensions > 360 && windowDimensions < 375:
        return 85;
      case windowDimensions <= 400:
        return 80;
      case windowDimensions <= 600:
        return 80;
      case windowDimensions <= 800:
        return 80;
      case windowDimensions <= 1300:
        return 50;
      default:
        return 40;
    }
  };

  return (
    <section
      className={
        'w-full pt-8 bg-gray-200 lg:pl-[8px] lg:pr-[8px] xl:pl-[12rem] xl:pr-[12rem] xl:pb-[80px] xl:pt-[80px]'
      }
      data-testid={'choose-plan-section'}
    >
      <div
        className={
          'hidden xl:flex w-full flex-row xl:justify-between items-center gap-8 mb-12'
        }
      >
        <h2 className={'font-bold text-4xl'}>Choose the right phone for you</h2>
        <Link
          href={'/#details-section'}
          className={'text-sm font-medium text-primary'}
        >
          View rental details
        </Link>
      </div>
      <div className="hidden xl:flex justify-between items-stretch gap-6">
        {
          //@ts-ignore
          flexProductSort.map((product, index) => (
            <div
              key={index}
              data-testid={'plan-card'}
              className="w-[33.33%] md:w-[364px]"
            >
              <ChoosePlanCard
                bgColor={index === 1 ? 'bg-flexThemeBlue' : 'bg-black'}
                product={product}
                soldOutButtonText={homepagedata?.soldOutButtonText}
                subscribeButtonText={homepagedata?.subscribeButtonText}
                cardDescriptionText={homepagedata?.cardDescriptionText}
              />
            </div>
          ))
        }
      </div>
      <div className={'flex xl:hidden mt-0'}>
        <Carousel
          showArrows={false}
          showIndicators={true}
          showThumbs={false}
          infiniteLoop={false}
          dynamicHeight={false}
          selectedItem={0}
          centerMode={true}
          centerSlidePercentage={getSlidePercentage()}
          showStatus={false}
          width="100vw"
          className="w-inherit"
        >
          {
            //@ts-ignore
            flexProductSort.map((product, index) => (
              <div
                key={index}
                data-testid={'plan-card'}
                className="flex justify-center items-center"
              >
                <ChoosePlanCard
                  bgColor={index === 1 ? 'bg-flexThemeBlue' : 'bg-black'}
                  product={product}
                  soldOutButtonText={homepagedata?.soldOutButtonText}
                  subscribeButtonText={homepagedata?.subscribeButtonText}
                  cardDescriptionText={homepagedata?.cardDescriptionText}
                />
              </div>
            ))
          }
        </Carousel>
      </div>
    </section>
  );
}
