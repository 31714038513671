import React from 'react';

import { HowItWorksProps } from './types';

export default function HowItWorksCard(props: HowItWorksProps) {
  const { step, title, description, icon: Icon } = props;

  return (
    <section
      className={
        'w-full lg:w-[230px] lg:h-96 xl:w-[360px] xl:h-96 border-2 border-bgLightGrey rounded-xl p-6 lg:p-4 xl:p-8 flex flex-col justify-start items-center'
      }
    >
      <div className="xl:block text-center">
        <h1
          className={
            'font-extrabold text-4xl text-flexThemeBlue mb-2 ml-2 text-center'
          }
        >
          {step}
        </h1>
        <Icon size={60} className={'mb-2 ml-2 text-flexThemeBlue'} />
      </div>
      <h4 className={'font-medium font-bold text-2xl mb-2 text-center'}>
        {title}
      </h4>
      <p className={'text-center text-xl mb-0 xl:mb-4 font-normal'}>
        {description}
      </p>
    </section>
  );
}
