import { Accordion } from '@chakra-ui/react';
import { faqsProps } from '../../../globalTypes';
import AccordianItemAtom from '@/components/atoms/AccordianItemAtom';

interface AccordianControllerProps {
  faqs: faqsProps[];
}

export default function AccordianController(props: AccordianControllerProps) {
  return (
    <Accordion allowToggle className="w-full">
      {props.faqs &&
        props.faqs.length &&
        props.faqs.map((faq, index: number) => (
          <AccordianItemAtom
            key={faq.question}
            title={faq.question}
            showBottomBorder={true}
            showBottomTop={index === 0 ? true : false}
            paddingXsmall={true}
          >
            <div className="px-4">{faq.answer}</div>
          </AccordianItemAtom>
        ))}
    </Accordion>
  );
}
