'use client';
import React from 'react';
import { useRouter } from 'next/router';
import AccordianController from '../../molecules/AccordianController';
import { visitHelpCenterGtm } from '@/lib/Gtmlib';
import { Details } from './types';

export default function FAQ(props: Details) {
  const { faqs, topParagraph, helpLink } = props;

  const router = useRouter();

  const handleVisitHelpCenter = () => {
    visitHelpCenterGtm();

    router.push(helpLink?.link);
  };

  return (
    <section className={'w-full xl:px-48 xl:py-10'}>
      <div
        className={
          'xl:border-2 xl:border-borderGrey xl:rounded-2xl pt-2 px-6 xl:pt-6 xl:px-6'
        }
      >
        <div className={'flex flex-col mt-4 mb-8'}>
          <h2
            className={
              'text-center xl:text-start text-black lg:text-flexThemeBlue font-[450] text-4xl line-10'
            }
            data-testid={'faq-title'}
          >
            Frequently asked questions
          </h2>
          <p className="font-normal text-xl">{topParagraph}</p>
        </div>
        <div className={'w-full h-full flex flex-row justify-between pb-6'}>
          <AccordianController faqs={faqs} />
        </div>
      </div>
      <div className="text-center px-4 pt-4 pb-8 xl:px-10 xl:py-10">
        <button
          onClick={handleVisitHelpCenter}
          className="text-base bg-transparent border-primary border-2 rounded text-primary font-medium text-center pt-2.5 pb-2.5 pl-4 pr-4"
          data-tracking-id="helpcenter-home"
        >
          {helpLink?.text}
        </button>
      </div>
    </section>
  );
}
