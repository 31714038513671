'use client';
import React from 'react';
import { FaStar } from 'react-icons/fa';
import { ReviewProps } from 'globalTypes';
import Image from 'next/image';

export default function TestimonialCard(props: ReviewProps) {
  const { userImage, title, description, name } = props;

  return (
    <section
      className={
        'w-[260px] h-[400px] xl:w-[340px] xl:h-[490px] px-4 rounded-xl flex flex-col items-center bg-white'
      }
    >
      <Image
        src={`http:${userImage}`}
        alt={'User profile pic'}
        className={'roundedFull mt-4 object-cover'}
        width={96}
        height={96}
      />
      <h4 className={'uppercase text-lg font-thin text-flexThemeBlue my-2'}>
        Verified Buyer
      </h4>
      <h4 className={'font-medium text-lg xl:text-xl mb-2 text-center'}>
        {title}
      </h4>
      <p
        className={`text-center mb-4 text-xl h-52 font-normal max-h-[200px] overflow-y-auto `}
      >{`"${description}"`}</p>
      <h4 className={'font-medium text-lg xl:text-xl mb-2 text-center'}>
        {name}
      </h4>
      <div className={'w-full flex flex-row justify-center items-center mb-4'}>
        <FaStar size={14} className={'ml-2 text-flexThemeBlue'} />
        <FaStar size={14} className={'ml-2 text-flexThemeBlue'} />
        <FaStar size={14} className={'ml-2 text-flexThemeBlue'} />
        <FaStar size={14} className={'ml-2 text-flexThemeBlue'} />
        <FaStar size={14} className={'ml-2 text-flexThemeBlue'} />
      </div>
    </section>
  );
}
