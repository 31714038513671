'use client';
import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import WhyLoveFlexCard from '../whyLoveFlexCard';
import Image from 'next/image';
import { LoveFlexProps } from './types';

export default function WhyLoveFlexSection(props: LoveFlexProps) {
  const {
    fields: { whyYouLoveGaxellFlexDetails },
    loveFlexImage,
  } = props;
  const [windowDimensions, setWindowDimensions] = useState(0);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  const handleResize = () => {
    setWindowDimensions(window.innerWidth);
  };

  const getSlidePercentage = () => {
    if (windowDimensions <= 400) {
      return 80;
    }
    if (windowDimensions <= 600) {
      return 80;
    }
    if (windowDimensions <= 800) {
      return 45;
    }
    if (windowDimensions <= 1000) {
      return 40;
    }
    return 35;
  };
  return (
    <div className="relative">
      <Image
        src={`http:${loveFlexImage.fields?.file?.url}`}
        alt={loveFlexImage.fields?.description}
        className="flex xl:hidden h-[158px] w-full object-cover"
        width={500}
        height={764}
      />
      <section
        className={'w-full xl:px-48 xl:py-10 relative top-[-10] z-1 -mt-8'}
      >
        <h2
          className={
            'hidden xl:block text-xl text-center xl:text-start font-bold xl:text-4xl mb-12'
          }
        >
          Why you&apos;ll love Gazelle Flex
        </h2>
        <div
          className={'w-full flex justify-start items-start flex-row h-full'}
        >
          <div className={'hidden xl:flex w-1/2 items-stretch mr-14'}>
            <Image
              src={`http:${loveFlexImage.fields?.file?.url}`}
              alt={loveFlexImage.fields?.description}
              className="w-[500px] h-[764px] object-contain"
              width={500}
              height={764}
            />
          </div>
          <div
            className={'hidden xl:flex xl:pl-3 flex-row xl:flex-col'}
            data-testid={'why-love-flex-card'}
          >
            {whyYouLoveGaxellFlexDetails.map((item, index: number) => {
              return (
                <WhyLoveFlexCard
                  key={item.fields.maintext}
                  title={item.fields.maintext}
                  description={item.fields.childText}
                  iconUrl={item.fields.icon?.fields?.file?.url}
                  displayLine={
                    whyYouLoveGaxellFlexDetails.length === index + 1
                      ? false
                      : true
                  }
                />
              );
            })}
          </div>

          <div className={'flex xl:hidden '} data-testid={'why-love-flex-card'}>
            <Carousel
              showArrows={false}
              showIndicators={true}
              infiniteLoop={false}
              dynamicHeight={false}
              selectedItem={0}
              showStatus={false}
              centerMode={true}
              centerSlidePercentage={getSlidePercentage()}
              className="w-[100vw]"
              showThumbs={false}
            >
              {whyYouLoveGaxellFlexDetails.map((item, index: number) => {
                return (
                  <div
                    key={item.fields.maintext}
                    className="ml-[8px] mr-[8px] flex justify-center items-center "
                  >
                    <WhyLoveFlexCard
                      title={item.fields.maintext}
                      description={item.fields.childText}
                      iconUrl={item.fields.icon?.fields?.file?.url}
                      displayLine={
                        whyYouLoveGaxellFlexDetails.length === index + 1
                          ? false
                          : true
                      }
                    />
                  </div>
                );
              })}
            </Carousel>
          </div>
        </div>
      </section>
    </div>
  );
}
