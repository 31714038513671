import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { BsCheckLg } from 'react-icons/bs';
import { planSelectedGtm, planSubscribeGtm } from '@/lib/Gtmlib';
import { HeaderProps } from './types';
import { MdOutlineMail } from 'react-icons/md';
import { NotifyMeContext } from '@/context/NotifyMeContext';

export default function StickyHeader(props: HeaderProps) {
  const { products, subscribeButtonText } = props;
  const sortedProducts = [...products].sort((a, b) => {
    const first = a.handle.toLowerCase();
    const second = b.handle.toLowerCase();
    return first.localeCompare(second);
  });
  const router = useRouter();

  let { toggleModal, updateProductHandle } = React.useContext(NotifyMeContext);

  const [planSelected, setPlanSelected] = useState('iphone-13');
  const [productAvailable, setProductAvailable] = useState<boolean>(true);

  const handleSetPlanSelected = (plan: any, availableForSale: boolean) => {
    planSelectedGtm(plan);

    setPlanSelected(plan);
    setProductAvailable(availableForSale);
  };

  const handleSubscribe = () => {
    planSubscribeGtm(planSelected);

    if (productAvailable) {
      router.push(`/product/${planSelected}`);
    } else {
      updateProductHandle(planSelected);
      toggleModal();
    }
  };

  const selectedStyling =
    'border-2 border-primary text-primary font-semibold bg-primary bg-opacity-10';

  return (
    <section
      data-testid={'sticky-header'}
      className={
        'h-auto fixed bottom-0 z-10 xl:top-0 left-0 right-0 w-full xl:h-24 px-4 xl:px-48 bg-white flex flex-col xl:flex-row justify-between items-center shadow-md'
      }
    >
      <div className={'flex flex-row p-2'}>
        {sortedProducts.map((product, index) => (
          <div
            key={index}
            data-testid={'product-container'}
            className={`cursor-pointer w-[110px] md:w-[150px] xl:w-[12rem] h-16 flex justify-center items-center flex-col border rounded-md mr-3 ${
              product.handle === planSelected ? selectedStyling : null
            }`}
            data-tracking-id={`${product.handle}-home-subscribe-sticky`}
            onClick={() =>
              handleSetPlanSelected(product.handle, product.availableForSale)
            }
          >
            <p
              className={`text-xl ${
                product.handle === planSelected ? 'font-[450]' : 'font-[390]'
              }`}
            >
              {product.title}
            </p>
            <p
              className={`text-sm xl:text-base mt-1 ${
                product.handle === planSelected ? 'font-[450]' : 'font-[390]'
              }`}
            >
              ${product.price}/mo
            </p>
          </div>
        ))}
      </div>
      {productAvailable ? (
        <div className={'hidden xl:block'}>
          <p
            className={
              'text-sm text-bgDarkGrey mb-1 flex flex-row items-center'
            }
          >
            <span>
              <BsCheckLg size={20} className={'mr-2 text-flexThemeBlue'} />
            </span>{' '}
            Use the phone with any carrier
          </p>
          <p
            className={
              'text-sm text-bgDarkGrey mb-1 flex flex-row items-center'
            }
          >
            <span>
              <BsCheckLg size={20} className={'mr-2 text-flexThemeBlue'} />
            </span>{' '}
            You may be eligible for upgrade after 6 months
          </p>
          <p
            className={
              'text-sm text-bgDarkGrey mb-1 flex flex-row items-center'
            }
          >
            <span>
              <BsCheckLg size={20} className={'mr-2 text-flexThemeBlue'} />
            </span>{' '}
            Free shipping and returns
          </p>
        </div>
      ) : (
        <div className="text-sm text-[#F44336] mr-1">TEMPORARILY OUT OF STOCK</div>
      )}
      <button
        onClick={handleSubscribe}
        className={
          `${!productAvailable? 'min-w-[261px]' : ''} w-full xl:w-auto mb-2 xl:mb-0 block text-white bg-primary hover:bg-hoverPrimary focus:ring-4 focus:outline-none mt-2 xl:mt-0 focus:ring-white rounded-md px-5 py-3 xl:py-5 text-center font-semibold`
        }
        data-tracking-id="home-subscribe-sticky"
      >
        {productAvailable ? (
          subscribeButtonText
        ) : (
          <div className="flex flex-row justify-center items-center">
            <MdOutlineMail size={20} className={'mr-2'} />
            <div>Notify Me When Available</div>
          </div>
        )}
      </button>
    </section>
  );
}
