import React from 'react';
import HowItWorksCard from '../howItWorks';
import { FaArrowDown, FaArrowRight } from 'react-icons/fa';
import { iconlib } from '@/lib/Iconlib';
import { HowItWorks } from './types';

export default function HowItWorksSection({ howItWorks }: HowItWorks) {
  return (
    <section
      className={'w-full px-4 py-8 xl:pl-48 xl:pr-48 md:pl-[96px] md:pr-[96px]'}
    >
      <h2 className={'text-center md:text-start font-bold text-4xl mb-12'}>
        How it works
      </h2>
      <div className="w-full h-auto lg:flex flex-col lg:flex-row lg:items-center justify-between">
        {howItWorks.map((step,i) => (
          <React.Fragment key={step.howItWorksHeading || i}>
            <div key={step.step}>
              <div className={'flex flex-col lg:flex-row lg:items-center'}>
                <div
                  className={step.step === 3 ? 'mr-0' : 'mr-0'}
                  data-testid={'step-card'}
                >
                  <HowItWorksCard
                    step={step.step}
                    title={step.howItWorksHeading}
                    description={step.howItWorksExplanation}
                    icon={iconlib[`${step.iconName}`]}
                  />
                </div>

                <div
                  className={`m-auto my-2 block lg:hidden ${
                    step.step === 3 ? 'lg:block hidden' : 'lg:hidden block'
                  }`}
                >
                  <FaArrowDown
                    size={40}
                    className={'mx-4 text-flexThemeBlue'}
                  />
                </div>
              </div>
            </div>
            <div
              className={`flex items-center justify-center hidden lg:p-3 lg:block ${
                step.step === 3
                  ? 'block lg:hidden relative'
                  : 'hidden lg:block relative'
              }`}
            >
              <FaArrowRight size={40} className={'text-flexThemeBlue'} />
            </div>
          </React.Fragment>
        ))}
      </div>
    </section>
  );
}
