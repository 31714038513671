import React from 'react';
import { HiOutlineInformationCircle } from 'react-icons/hi';
import { iconlib } from '@/lib/Iconlib';
import TooltipWithTouch from '@/components/atoms/TooltipWithTouch';
import Image from 'next/image';
import { Props, DetailsArray } from './types';

export default function DetailsSection(props: Props) {
  const { details } = props;

  return (
    <section className={'w-full px-4 py-8 xl:px-48 xl:py-24 bg-[#F2F2F2]'}>
      <h2 className={'text-center xl:text-start font-bold text-4xl mb-4'}>
        The details
      </h2>
      <div className="w-full h-auto grid col-auto md:grid-cols-2 items-center justify-between">
        <div
          className={'w-full h-auto  flex flex-col items-start justify-between'}
          data-testid={'details-section'}
        >
          {details?.productDetails.map((item: DetailsArray) => {
            const Icon = iconlib[`${item.fields.iconName}`];
            return (
              <div
                key={item.fields.maintext}
                className={
                  'w-full px-4 xl:px-0  text-base xl:text-start flex flex-col xl:items-start xl:justify-start xl:flex-row my-4 xl:text-lg'
                }
              >
                <div className="flex flex-row">
                  <span>
                    <Icon size={28} className={'mr-4 text-flexThemeBlue'} />
                  </span>
                  <span className="text-xl font-normal">
                    {item.fields.maintext}

                    {item.fields.tooltipText && item.fields.maintext && (
                      <>
                        <span className="inline">
                          <TooltipWithTouch
                            hasArrow
                            label={item.fields.tooltipText}
                            bg={'#ff6900'}
                            color={'#fff'}
                            className={'xl:w-full w-[310px] p-2'}
                          >
                            <span>
                              <span className="hidden xl:inline">
                                <HiOutlineInformationCircle
                                  color={'#ff6900'}
                                  size={28}
                                  className={'ml-2 inline '}
                                />
                              </span>
                              <button
                                className={
                                  'pt-2 flex flex-row items-center text-[#FF6900] text-base font-medium xl:hidden'
                                }
                                type="button"
                              >
                                <span className="flex text-base text-[#FF6900] font-bold">
                                  {'Learn more'}
                                </span>
                              </button>
                            </span>
                          </TooltipWithTouch>
                        </span>
                      </>
                    )}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
        <div
          className={'hidden xl:flex h-64 justify-center items-center ml-10'}
        >
          <Image
            src={`http:${details?.detailsImage?.fields?.file?.url}`}
            alt="placeholder"
            className="object-contain"
            width={540}
            height={264}
          />
        </div>
      </div>
    </section>
  );
}
