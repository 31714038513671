import React from 'react';
import Image from 'next/image';
import { WhyLoveFlexProps } from './types';

export default function WhyLoveFlexCard(props: WhyLoveFlexProps) {
  const { title, description, iconUrl, displayLine } = props;

  return (
    <section
      className={
        'h-auto min-w-[250px] m-4 xl:m-0 p-5 xl:p-0 shadow-lg rounded-[0.8rem] xl:shadow-none xl:rounded-none flex flex-col items-center justify-center xl:items-start xl:justify-start xl:flex-row bg-white'
      }
    >
      <div className={'w-8 flex items-start justify-start my-3 xl:mt-1 mr-2'}>
        <Image
          src={iconUrl}
          className="h-5 xl:h-6"
          alt="Icon"
          width={24}
          height={24}
        />
      </div>
      <div>
        <h4
          className={
            'font-medium text-xl xl:text-2xl text-center xl:text-start mb-2'
          }
        >
          {title}
        </h4>
        <p
          className={
            'w-full text-xl text-center xl:text-start xl:w-[500px] font-normal'
          }
        >
          {description}
        </p>
        {displayLine ? (
          <div className={'hidden xl:block h-px w-28 bg-black my-6'}></div>
        ) : null}
      </div>
    </section>
  );
}
