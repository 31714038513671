import { useRouter } from 'next/router';
import Image from 'next/image';
import { flexIntroInfo } from './types';

export default function FlexIntro(props: flexIntroInfo) {
  const {
    mainHeading,
    h2title,
    paymentStatement,
    refurbishedStatement,
    monthStatement,
    imageUrl,
    handleSubscribe,
    subscribeButtonText,
    flexIntroSubscriberQuestion,
    mobileRegular,
    circularDollarRegular,
    calendarRegular,
    upgradeEligibility,
  } = props;

  const router = useRouter();

  return (
    <section
      className={
        'w-full flex flex-col md:flex-row px-6 py-6 md:px- xl:px-48 xl:py-32 justify-around items-around'
      }
    >
      <div className={'w-full xl:w-2/3 h-full'}>
        <h1 className={'text-3xl xl:text-7xl font-semibold text-flexThemeBlue'}>
          {mainHeading}
        </h1>
        <h6 className={'text-xl xl:text-4xl font-medium mt-3 xl:mt-5'}>
          {h2title}
        </h6>
        <div className="mt-8 xl:mt-12 mb-10">
          <p
            className={
              'flex flex-row my-2 xl:my-4 text-base xl:text-2xl font-base xl:font-medium'
            }
          >
            <span className="my-auto">
              <Image
                src={`http:${mobileRegular?.fields?.file?.url}`}
                alt={mobileRegular?.fields?.description}
                className={'mr-4 text-flexThemeBlue'}
                width={24}
                height={24}
              />
            </span>
            {paymentStatement}
          </p>
          <p
            className={
              'flex flex-row my-2 xl:my-4 text-base xl:text-2xl font-base xl:font-medium'
            }
          >
            <span className="my-auto">
              <Image
                src={`http:${calendarRegular?.fields?.file?.url}`}
                alt={calendarRegular?.fields?.description}
                className={'mr-4 text-flexThemeBlue'}
                width={24}
                height={24}
              />
            </span>
            {monthStatement}
          </p>
          <p
            className={
              'flex flex-row my-2 xl:my-4 text-base xl:text-2xl font-base xl:font-medium'
            }
          >
            <span className="my-auto">
              <Image
                src={`http:${circularDollarRegular?.fields?.file?.url}`}
                alt={circularDollarRegular?.fields?.description}
                className={'mr-4 text-flexThemeBlue'}
                width={24}
                height={24}
              />
            </span>
            {refurbishedStatement}
          </p>
        </div>
        <button
          onClick={handleSubscribe}
          type="button"
          data-tracking-id="homepage-rentnow-button"
          className={
            'w-full md:w-auto xl:w-auto text-white bg-primary hover:bg-hoverPrimary focus:ring-4 focus:outline-none focus:ring-white rounded-lg px-5 py-2.5 text-lg text-center font-bold'
          }
        >
          {subscribeButtonText}
        </button>
        <div
          className={
            'w-full md:flex xl:flex flex-column md:flex-row xl:flex-row text-center items-center mt-4'
          }
        >
          <p className="font-medium text-base text-[#5B5B5B]">
            {flexIntroSubscriberQuestion}
          </p>
          <button
            onClick={() => router.push(upgradeEligibility.link)}
            className="font-medium text-base text-[#FF6900] cursor-pointer md:ml-1 xl:ml-1"
          >
            {upgradeEligibility.text}
          </button>
          .
        </div>
      </div>
      <div
        className={
          'hidden md:flex xl:flex w-[421px] md:h-[300px] xl:h-[413px] xl:justify-center items-center'
        }
      >
        <Image
          src={`http:${imageUrl}`}
          alt={'Gazelle Flex intro'}
          className="min-w-[421] w-[421] min-h-[413] max-h-[413]"
          width={421}
          height={413}
        />
      </div>
    </section>
  );
}
